@font-face {
    font-family: 'folks-bold';
    src: url('../fonts/Folks-Bold.ttf');
}
@font-face {
    font-family: 'folks-heavy';
    src: url('../fonts/Folks-Heavy.ttf');
}
@font-face {
    font-family: 'folks-light';
    src: url('../fonts/Folks-Light.ttf');
}
@font-face {
    font-family: 'folks-normal';
    src: url('../fonts/Folks-Normal.ttf');
}