$hoverwidth: 14vw;
$techfactoryBlack: #33363B;
$techfactoryBlue: #00AEEF;

body{
	margin: 0;
	white-space:nowrap;
	overflow: hidden;
	color: $techfactoryBlack;
    font-family: 'folks-light';

	.column33{
     	width: (100% / 3);
		height: 100%;
		display: inline-block;
		position: absolute;
		z-index: 50;
		overflow: hidden;
		cursor: hand;

		&.hoverout{
//			@include transition('width 0.4s ease-in-out');
			@include transition('all 0.4s ease-in-out');
		}

		&.hoverin{
			width: calc((100% / 3) + #{$hoverwidth});
//			@include transition('width 0.4s ease-in-out');
			@include transition('all 0.4s ease-in-out');
		}
		&.open{
//			@include transition('width 1s ease-in-out, left 1s ease-in-out');
			@include transition('all 1s ease-in-out');
			width: 100%;
			left: 0 !important;

			.contentrow{
				cursor: hand;
				&:hover{
					background-color: #5FB4B3;
				}
			}
		}

		&.close{
//			@include transition('width 1s ease-in-out, left 1s ease-in-out');
			@include transition('all 1s ease-in-out');
		}

		.titlecolumn{
			width: calc(100vw / 3);
			left: 0;
			overflow: hidden;
		}
		.contentcolumn{
			width: calc((200vw / 3) + 25px);
			left: calc(100vw / 3);
			overflow-x: hidden;
			overflow-y: auto;
    		padding-right: 25px;
		}
		.titlecolumn, .contentcolumn{
			height: 100%;
			display: inline-block;
			white-space:nowrap;
		}

		.contentcolumn{
			z-index: 1;
		}

		.titlecolumn{
			text-align: center;
			position: relative;
			img{
				height: 30vh;
				width: 30vw;
				max-width: 30vw;
				max-height: 30vh;
				top: 35vh;
				left: 2vw;
				position: absolute;
			}
			p{
    			font-family: 'folks-bold';
				bottom: calc(35vh - 1.5em);
				font-size: 4em;
				text-align: center;
				width: 30vw;
				left: 2vw;
				margin: 0;
				padding: 0;
				position: absolute;
				overflow: hidden;
				opacity: 0;
//				@include transition('opacity 0.4s ease-in-out');
				@include transition('all 1s ease-in-out');
			}
		}
		&.open .titlecolumn p, &.hoverin .titlecolumn p, &.close .titlecolumn p, .titlecolumn:hover p{
			opacity: 1;
//			@include transition('opacity 0.4s ease-in-out');
			@include transition('all 1s ease-in-out');
		}
		&.close .titlecolumn p{
			-webkit-transition-delay: 1s; /* Safari */
    		transition-delay: 1s;
		}
		.contentrow{
			clear: left;
			overflow: hidden;
			> .preview{
	   			width: $hoverwidth * 0.9;
	   			> img{
	   				max-height: 100%;
	   				max-width: 100%;
	   			}
			}
			> .content{
				white-space: normal;
				width: calc((200vw / 3) - (#{$hoverwidth} * 1.1));
				> :first-child {
					margin-top: 0;
				}
				> :last-child {
					margin-bottom: 0;
				}
			}
			> .preview, > .content{
				padding: $hoverwidth * 0.05;
				float: left;
			}
			&.open{
//				@include transition('max-height 1s ease-in-out');
				@include transition('all 1s ease-in-out');
			}
			&.close{
//				@include transition('max-height 0.8s ease-in-out');
				@include transition('all 0.8s ease-in-out');
			}
		}
	}

	#column_website{
		&.hoverout, &.open{
			z-index: 80;
		}
		&.hoverin, &.close{
			z-index: 75;
		}
		left: 0;
		background-color: #4EA3A2;
	}
	#column_apps{
		&.hoverout, &.open{
			z-index: 70;
		}
		&.hoverin, &.close{
			z-index: 65;
		}
     	left: (100% / 3);
		background-color: #79A34E;
	}
	#column_about{
		&.hoverout, &.open{
			z-index: 60;
		}
		&.hoverin, &.close{
			z-index: 55;
		}
     	left: (200% / 3);
		background-color: #AB3E3F;
	}

	.test{
		background-color: red !important;
	}
}
